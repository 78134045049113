import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {Modal, ModalBody} from "reactstrap";
import Login from "../Login/login";
import {Link, Redirect} from 'react-router-dom'
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import RegistrarCuenta from "../Registrar_cuenta/registrarCuenta";
import {useSelector} from "react-redux";
import {fetchEstadoFalse} from "../../contexts/Redux/reducers/login";
import {useDispatch} from 'react-redux'
import axios from "axios";
import {fetchInfoUser} from "../../contexts/Redux/reducers/usuario";
import Dialogo from "../../components/Dialogo/dialogo";
import UpdateSub from "../Update_sub/UpdateSub";
import StartupForm from "../Update_sub/StartupForm";
import moment from 'moment'
import CloseIcon from "@mui/icons-material/Close";
import {validarToken} from "../utils/token";

const pages = ['Home','Desafío Destacado','Desafíos', 'Soluciones Destacadas', 'Planes', 'Contacto'];

const ResponsiveAppBar =() => {
    const informacionUsuario = useSelector(state => state.usuarios.infoUser);

    //console.log(informacionUsuario);

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    // const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [dataInfo, setDataInfo] = React.useState({});
    const [id, setid] = React.useState(null);
    const [modal, setmodal] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);
    const [idmodal, setidmodal] = React.useState(false);
    const {estadoLogin} = useSelector(state => state.estadoLogin);
    // const [estadoLogin2, setEstadoLogin2] = React.useState(estadoLogin);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const dispatch = useDispatch()
    const [redirectHome, setRedirectHome] = useState(false); // suscripcion

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
        // console.log(anchorElNav)
        // console.log(open)
    };
    const handleClose = () => {
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    React.useEffect(() => {

        if (estadoLogin === true) {
            if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                console.log('SIGO LOGUEADO')
            }
            infoUsuarioHanndle()
        }else{
            if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                console.log('ESTOY DESLOGUEADO')
            }

            // forceUpdate()

        }

    }, []);

    function infoUsuarioHanndle() {
        const token2 =  localStorage.getItem('token_3')
            // console.log("ENTRE A handleuser navbar")
            axios.post(`${process.env.REACT_APP_API_STREAMING}/datosuser`, {
                email: localStorage.getItem('email')
            },{headers:{authorization: token2}})
                .then((response) => {
                    // console.log('ACTUALIZADO INFORMACION DE USUARIO response')
                    let informacion = response.data
                    const noValido = / /
                    if (noValido.test(informacion.name)) {
                        let nombre = informacion.name.split(" ",)
                        let nombrep = nombre[0].charAt(0).toUpperCase();
                        let apellido = nombre[1].charAt(0).toUpperCase();
                        let n = nombre[0].charAt(0).toUpperCase() + nombre[0].slice(1);
                        let a = nombre[1].charAt(0).toUpperCase() + nombre[1].slice(1);
                        informacion.nombreAbreviado = `${nombrep}${apellido}`
                        informacion.nombre = `${n}`
                        informacion.apellido = `${a}`
                        setDataInfo(informacion)
                        dispatch(fetchInfoUser(informacion))
                    }
                    else {
                        let nombre = informacion.name
                        let nombrep = nombre[0].charAt(0).toUpperCase();
                        informacion.nombreAbreviado = nombrep
                        informacion.nombre = nombre
                        informacion.apellido = 's'
                        setDataInfo(informacion)
                        dispatch(fetchInfoUser(informacion))
                    }
                })
                .catch((error) => {
                    if (validarToken(error))  dispatch(fetchEstadoFalse())
                    console.log('ERROR :', error.response.data.error)
                })
    }

    function handdleModal(value) {
        setmodal(true)
        setidmodal(value)
    }

    function cerrarModal() {
        setmodal(false)
    }

    const handlePlataforma =  async () => {
        let token = localStorage.getItem('token_2')
        let token2 = localStorage.getItem('token_3')
        let url = `${process.env.REACT_APP_API_I360}/login?token=${token}`

        if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
            console.log("comsultando si puedo entrar a plataforma")
        }

        await axios.post(`${process.env.REACT_APP_API_STREAMING}/datosuser`, {
            email: localStorage.getItem('email')
        }, {headers: {authorization: token2}})
            .then((response) => {
                    if (dataInfo.diasvigencia > 0 || dataInfo.state === 1) {
                        if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                            console.log("TOKEN VALIDO REDIRECCIONANDO ....")
                        }
                        window.open(url, '_self', 'noopener,noreferrer')
                    } else {
                        setRedirect(true)
                    }
            }).catch(error => {
                if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                    console.log("TOKEN CADUCADO, REDIRECCIONANDO ....")
                }
                if( validarToken(error)) setRedirectHome(true)
                console.log('ERROR :', error.response.data.error)
            })

    }
    const renderMenu = (text) => {
        switch (text) {
            case 'Home':
                setid('#home')
                break;
            case 'Desafío Destacado':
                setid('#desafiodestacado')
                break;
            case 'Desafíos':
                setid('#desafios')
                break;
            case 'Soluciones Destacadas':
                setid('#solucionesdestacadas')
                break;
            case 'Planes':
                setid('#planes')
                break;
            /* case 'Lanza tu llamado':
                setid('#lanzarllamado')
                break; */
            case 'Contacto':
                setid('#contacto')
                break;
            default :
                setid('/')
                break
        }
    }
    const renderMenuMovil = (text) => {

        switch (text) {
            case 'Home':
                setid('#home')
                handleCloseNavMenu()
                break;
            case 'Desafíos':
                setid('#desafios')
                handleCloseNavMenu()
                break;
            case 'Soluciones Destacadas':
                setid('#solucionesdestacadas')
                handleCloseNavMenu()
                break;
            case 'Planes':
                setid('#planes')
                handleCloseNavMenu()

                break;
            case 'Contacto':
                setid('#contacto')
                handleCloseNavMenu()
                break;
            /* case 'Lanza tu llamado':
                setid('#lanzarllamado')
                handleCloseNavMenu()
                break; */
            default :
                setid('/')
                handleCloseNavMenu()
                break
        }
    }
    const handleOpenNavMenu = (event) => {
        // console.log(event)
        setAnchorElNav(event.currentTarget);
    };

    function handleCerrarSesion() {
        localStorage.removeItem('token_2');
        localStorage.removeItem('estadoLogin')
        localStorage.clear();
        dispatch(fetchEstadoFalse())
        setAnchorElNav(null);
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    return (
        <div className="app-header header--transparent sticker" id="main-menu">
            <AppBar position="static" style={{background: 'rgb(66, 66, 66)', color: '#ffff'}}>

                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {/*<AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />*/}

                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                display: {xs: 'none', md: 'flex'},
                                fontFamily: 'monospace',
                                fontWeight: 900,
                                letterSpacing: '.4rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <a href={`/home`}>
                                <img className="logo-1" id="styleLogo"
                                     width='100%'
                                     src={require('../../assets/images/logo/Logo-InnovaConnect-blanco.png').default}
                                     alt="app landing"/>
                                {/*<img className="logo-2" src={require('../assets/images/logo/Logo-InnovaConnect-blanco.png').default} alt="app landing"/>*/}
                                {/*<img className="logo-3" src={require('../assets/images/logo/Logo-InnovaConnect-blanco.png').default} alt="app landing"/>*/}
                            </a>
                        </Typography>

                        {/* VERSION MOVIL*/}
                        <Box sx={{flexGrow: 2, display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={ handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={() => renderMenuMovil(page)}>
                                        <a href={`/${id}`}>{page}</a>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        <Typography
                            variant="h5"
                            noWrap
                            href={'/'}
                            sx={{
                                mr: 2,
                                display: {xs: 'flex', md: 'none'},
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <a href={`/home`}>
                                <img className="logo-1" id="styleLogo"
                                     src={require('../../assets/images/logo/Logo-InnovaConnect-blanco.png').default}
                                     alt="app landing"/>
                                {/*<img className="logo-2" src={require('../assets/images/logo/Logo-InnovaConnect-blanco.png').default} alt="app landing"/>*/}
                                {/*<img className="logo-3" src={require('../assets/images/logo/Logo-InnovaConnect-blanco.png').default} alt="app landing"/>*/}
                            </a>
                        </Typography>
                        <Box
                            sx={{flexGrow: 1, display: {xs: 'none', md: 'flex',}}}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    href={id}
                                    style={{fontWeight: 900}}
                                    onClick={() => renderMenu(page)}
                                    sx={{my: 2, color: 'white', display: 'block'}}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>
                        {estadoLogin ?
                            <div>
                                <IconButton onClick={() => handleToggle()}
                                            ref={anchorRef}
                                            id="composition-button"
                                            aria-controls={open ? 'composition-menu' : undefined}
                                            aria-expanded={open ? 'true' : undefined}
                                            aria-haspopup="true"
                                >
                                    <div className='avatar' style={{borderRadius: '7px', background: '#474646', padding: '10px'}}>
                                        <div>
                                            <Avatar sx={{background: '#fd601c', width: 51, height: 50, fontWeight: 900}}
                                                    alt={informacionUsuario.nombreAbreviado}
                                                    src={informacionUsuario.foto}
                                            >{informacionUsuario.nombreAbreviado}</Avatar>
                                        </div>
                                        <div className='text'>
                                            <span style={{fontWeight: '700', fontSize: '17px', color: '#FD601C', height: '20px'}}>Bienvenido/a</span>
                                            <span style={{fontWeight: '700', fontSize: '12px', color: '#ffffff', marginBottom: '10px'}}>{informacionUsuario.nombre}</span>
                                        </div>
                                    </div>
                                </IconButton>
                                <Popper
                                    open={open}
                                    anchorEl={anchorRef.current}
                                    placement="bottom-start"
                                    transition
                                    disablePortal
                                >
                                    {({TransitionProps, placement}) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin:
                                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                                            }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={() => handleClose()}>
                                                    <MenuList
                                                        autoFocusItem={false}
                                                        id="composition-menu"
                                                        aria-labelledby="composition-button"
                                                        onKeyDown={() => handleListKeyDown()}
                                                    >
                                                        <MenuItem><Link to={'/micuenta'}>Cuenta</Link></MenuItem>
                                                        <MenuItem onClick={() => handlePlataforma()}
                                                                  disabled={!(informacionUsuario.diasvigencia > 0 || informacionUsuario.status === 1)}
                                                        >
                                                            <span>Plataforma</span>
                                                        </MenuItem>
                                                        <MenuItem onClick={() => handleCerrarSesion()}>
                                                            <span>Cerrar sesión</span></MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>
                            : <div style={{marginLeft: '30px'}}>
                                <button className="button-default button-olive"
                                        id="ButtonNavBar"
                                        type="button"
                                        onClick={() => {
                                            handdleModal(1)
                                        }}>CREAR CUENTA
                                </button>
                                <button className="button-default button-olive"
                                        style={{borderRadius: '4px', fontSize: '13px', marginLeft: '9px'}} type="button"
                                        onClick={() => {
                                            handdleModal(2)
                                        }}>LOGIN
                                </button>

                            </div>}

                    </Toolbar>
                    <Modal isOpen={modal} toggle={() => cerrarModal()}>
                        <div style={{textAlign: 'end', margin: '13px'}}>
                            <IconButton aria-label="fingerprint" color="secondary" onClick={() => cerrarModal()}>
                                <CloseIcon style={{color: 'grey',}} fontSize={"medium"}/>
                            </IconButton>
                        </div>
                        <ModalBody style={{marginTop: '-14px'}}>
                            {idmodal === 2 ? <Login cerrarModalLogin={() => cerrarModal()}
                                                    abrirModal={() => handdleModal(2)}
                                                    abrirDialogo={() => handdleModal(4)}
                                                    navBar2={true}
                                                    infoUsuario={() => infoUsuarioHanndle()}/> :
                                idmodal === 3 ? <Dialogo diasvigencia={informacionUsuario.diasvigencia}
                                                         fechaVigencia={moment(informacionUsuario.fecha_vigencia).format('DD-MM-YYYY')}/> :
                                    idmodal === 4 ? <UpdateSub  cerrarModal={() => cerrarModal()}
                                                                abrirModal={() => handdleModal(5)}
                                                                diasvigencia={informacionUsuario.diasvigencia}
                                                                state={informacionUsuario.state}/> :
                                        idmodal === 5 ? <StartupForm    cerrarModal={() => cerrarModal()}
                                                                        abrirModal={() => handdleModal(6)}
                                                                        correo={informacionUsuario.email}/> :
                                    <RegistrarCuenta cerrarModal={() => cerrarModal()} navBar2={true}
                                                     infoUsuario={() => infoUsuarioHanndle()}/>}
                        </ModalBody>
                    </Modal>
                    {redirect ? (<Redirect push to="/micuenta"/>) : null}
                    {redirectHome ? (<Redirect push to="/"/>) : null}
                </Container>
            </AppBar>

        </div>

    );
}

export default ResponsiveAppBar