import axios from "axios";
import React, {useState} from 'react';
import logoInnova from "../../assets/images/logo/Logo InnovaConnect.png";
import {Col, Row, Button, Form} from "reactstrap";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import FormHelperText from '@mui/material/FormHelperText';
import './style.css';
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Alert from "@mui/material/Alert";
import {fetchEstadoTrue} from "../../contexts/Redux/reducers/login";
import {fetchInfoUser} from "../../contexts/Redux/reducers/usuario";
import {useDispatch} from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import Login from '../Login/login'
// import ReCAPTCHA from "react-google-recaptcha";
// const recaptchaRef = React.createRef();


const RegistrarCuenta = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [apellido, setApellido] = useState('')
    const [check, setCheck] = useState(false)
    const [nombre, setNombre] = useState('')
    const [codigoVerificacion, setCodigoVerificacion] = useState('')
    const [alert, setAlert] = useState(false)
    const [loading, setLoading] = useState(false)
    const [textoAlert, setTextoAlert] = useState('')
    const [validarEmail, setValidarEmail] = useState(false)
    const [validarNombre, setValidarNombre] = useState(false)
    const [validarApellido, setValidarApellido] = useState(false)
    const [validarPassword, setValidarPassword] = useState(false)
    const [validarPassword2, setValidarPassword2] = useState(false)
    const [tokenVerifyCode, setTokenVerifyCode] = useState('')
    const [validarPoliticas, setValidarPoliticas] = useState(false)
    const [irLogin, setIrLogin] = useState(false)
    const [irLogin1, setIrLogin1] = useState(false)
    const [textoValidarPoliticas, setTextoValidarPoliticas] = useState('')
    const [validarCodigoVerificacion, setValidarCodigoVerificacion] = useState(false)
    const [textoValidarEmail, setTextoValidarEmail] = useState('')
    const [textoValidarNombre, setTextoValidarNombre] = useState('')
    const [textoValidarApellido, setTextoValidarApellido] = useState('')
    const [textoValidarPassword, setTextoValidarPassword] = useState('La contraseña debe incluir como mínimo: 8 caracteres, 1 letra mayúscula, 1 letra minúscula y 1 número')
    const [textoValidarPassword2, setTextoValidarPassword2] = useState('')
    const [textoValidarCodigoVerificacion, setTextoValidarCodigoVerificacion] = useState('')
    const [mostrarPasoaPaso, setMostrarPasoaPaso] = useState(false)
    const [eye, setEye] = useState('password')
    const [eye2, setEye2] = useState('password')
    const [email2, setEmail2] = useState('vacio')
    const [activeStep, setActiveStep] = useState(0)
    const [completed, setCompleted] = useState({})
    const [steps] = useState(['Paso 1', 'Paso 2', 'Paso 3', 'Paso 4'])
    const dispatch = useDispatch()


    const handdlePassword = () => {
        if (eye === 'password') {
            setEye('text')
        } else {
            setEye('password')
        }
    }
    const handdlePassword2 = () => {
        if (eye2 === 'password') {
            setEye2('text')
        } else {
            setEye2('password')
        }
    }

    const handleIrLogin =()=>{
        setIrLogin1(true)
    }
    const handleInputs = (event, inp) => {
        switch (inp) {
            case 'email':
                setEmail(event.target.value.toLowerCase())
                break;
            case 'password':
                setPassword(event.target.value)

                break;
            case 'password2':
                setPassword2(event.target.value)
                break;
            case 'nombre':
                let nombre = event.target.value
                let nombre1
                if (nombre.length > 0) {
                    nombre1 = nombre[0].toUpperCase() + nombre.substring(1);
                } else {
                    nombre1 = event.target.value
                }
                setNombre(nombre1)
                break;
            case 'apellido':
                let apellido = event.target.value
                let apellido1
                if (apellido.length > 0) {
                    apellido1 = apellido[0].toUpperCase() + apellido.substring(1);
                } else {
                    apellido1 = event.target.value
                }
                setApellido(apellido1)
                break;

            case 'codigoVerificacion':
                setCodigoVerificacion(event.target.value)
                // if (password.length !== 0 && password2.length !== 0 && codigoVerificacion.length !== 0) {
                //     setDeshabilitado(false)
                // } else {
                //     setDeshabilitado(true)
                // }
                break;
            case 'check':
                setCheck(!check)
                console.log(check)
                break;
            default:
                break;
        }
    }
    const handdleBack = () => {
        let niucompleted = completed;
        niucompleted[activeStep] = false
        if (activeStep === 0) {
            setMostrarPasoaPaso(false)
        }
        let numero = activeStep === 0 ? 0 : activeStep - 1
        setActiveStep(numero)
        setCompleted(niucompleted)
        setLoading(false)
    }

    const handleNext = () => {
        // console.log('apreté submit')
        let numero = activeStep + 1
        let niucompleted = completed;

        if (activeStep === 0) {
            setTextoValidarCodigoVerificacion('Este código fue enviado a tu email. Revisa la carpeta SPAM si no lo ves en la bandeja de entrada')
            setMostrarPasoaPaso(true)
        }
        niucompleted[activeStep] = true

        setActiveStep(numero)
        setCompleted(niucompleted)
        setLoading(false)

        // console.log(state.activeStep)

    }

    const handlePaso0 = () => {
        handleNext()
    }
    const handlePaso1 = async () => {


        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        let listo = false

        setLoading(true)


        let existeCorreo = false
        let errord = false
        if (nombre.length === 0 || apellido.length === 0 || email.length === 0) {
            listo = false
            if (nombre.length === 0) {
                setValidarNombre(true)
                setTextoValidarNombre('Falta nombre')

            }
            if (apellido.length === 0) {
                setValidarApellido(true)
                setTextoValidarApellido('Falta apellido')

            }
            if (email.length === 0) {
                setValidarEmail(true)
                setTextoValidarEmail('Falta email')
            }else {
                let formato = emailRegex.test(email) ? '' : 'Formato incorrecto'
                if (formato) {
                    setValidarEmail(true)
                    setTextoValidarEmail(formato)
                    setLoading(false)
                }
            }
            setLoading(false)
        }
         else {
            setValidarNombre(false)
            setValidarApellido(false)
            setValidarEmail(false)
            setTextoValidarApellido('')
            setTextoValidarNombre('')
            setTextoValidarEmail('')


            if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                console.log('TODO BIEN PUEDO PASAR A VALIDAR CORREO')
                console.log(`${process.env.REACT_APP_API_INNOVACONNECT}/sign-up/user-exists,  email: ${email}`)
            }
                await axios.post(`${process.env.REACT_APP_API_INNOVACONNECT}/sign-up/user-exists`, {
                    email: email
                })
                    .then(async (data) => {
                        if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                            console.log("VALIDANDO QUE EL EMAIL NO EXISTA EN OTRA INSTANCIA")
                            console.log(data)
                        }

                        if(data.data.error === 409){
                            if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                                console.log("error:400 , existe en otra instancia", )
                            }
                            setTextoAlert(data.data.mensaje)
                            setAlert(true)
                            setLoading(false)
                            setIrLogin(true)
                            return
                        }
                        if (data.data.exists === true) {
                            // setAlert(true)
                            if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                                console.log("error: exists=true,  existe en la instancia",)
                            }
                            setValidarEmail(true)
                            setTextoValidarEmail('Ya existe una cuenta asociada a este correo')
                            setLoading(false)
                            existeCorreo = true;
                            errord = true

                        }
                        else{
                            if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                                console.log("sin error,  NO existe en ninguna instancia",)
                                console.log(data.data.exists)
                            }
                            listo=true
                            setLoading(false)
                            setTextoAlert('')
                            if (listo === true) {
                                if (email === email2) {
                                    // console.log("NO ES NECESARIO ENVIAR CORREO")
                                    handleNext()
                                    return;
                                }
                                await axios.post(`${process.env.REACT_APP_API_INNOVACONNECT}/sign-up/send-code`,
                                    {email: email})
                                    .then((data) => {
                                        let respuestaApi = data.data.token
                                        // console.log(respuestaApi)
                                        // console.log(data.data)
                                        setTokenVerifyCode(respuestaApi)
                                        // console.log(respuestaApi)
                                        if (respuestaApi !== '') {
                                            setEmail2(email)
                                            setValidarEmail(false)
                                            setLoading(false)
                                            handleNext()
                                        } else {
                                            setTextoAlert('No se encontro token')
                                            setLoading(false)
                                        }

                                    })
                                    .catch((error) => {
                                        // console.log("entre catch send code")
                                        setLoading(false)
                                        Swal.fire({
                                            icon: 'error',
                                            // title: 'Oops...',
                                            text: 'Ha ocurrido un error intente nuevamente',
                                        })
                                        console.log('[ERROR]: send-code', error)
                                    })
                            }
                            // if (email === email2) {
                            //     handleNext()
                            // }
                        }


                    })
                    .catch((error) => {
                        // console.log('entre a catch')
                        errord = true
                        setLoading(false)
                        Swal.fire({
                            icon: 'error',
                            // title: 'Oops...',
                            text: 'Ha ocurrido un error intente nuevamente',
                        })
                        console.log('[ERROR]: user-exists', error)
                        return;
                    })

            }

    }
    const handlePaso3 = () => {
        props.cerrarModal()
    }
    const handlePaso2 = async () => {
        setLoading(true)
        setValidarPassword(false)
        setValidarPassword2(false)
        setValidarCodigoVerificacion(false)
        setTextoValidarCodigoVerificacion('')
        setTextoValidarPassword2('')
        setTextoValidarPassword('')
        setValidarPoliticas(false)
        setTextoValidarPoliticas('')


        // setTextoValidarCodigoVerificacion('')

        if (codigoVerificacion.length === 0 || password.length === 0 || password2.length === 0 || check ===  false) {

            if (codigoVerificacion.length === 0) {
                setValidarCodigoVerificacion(true)
                setTextoValidarCodigoVerificacion('Falta codigo verificacion')
            }
            if (check===false) {
                setValidarPoliticas(true)
                setTextoValidarPoliticas('Debes aceptar las políticas de uso')

            }
            if (check===false) {
                setValidarPoliticas(true)
                setTextoValidarPoliticas('Debes aceptar las políticas de uso')

            }
            if (password.length === 0) {
                setValidarPassword(true)
                setTextoValidarPassword('Falta Password')

            } else {
                let formato = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
                let correcto = formato.test(password)
                // console.log(correcto)
                if (correcto === false) {
                    setValidarPassword(true)
                    setTextoValidarPassword('Tienes que crear una contraseña que debe incluir como mínimo: 8 caracteres, 1 letra mayúscula, 1 letra minúscula y 1 número')
                }
            }
            if (password2.length === 0) {
                setValidarPassword2(true)
                setTextoValidarPassword2('Falta confirmar password',)
            }
            setLoading(false)

        }
        else {
            // NO HAY CAMPOS VACIOS.

            let formato = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
            let correcto = formato.test(password) ? true : false


            // 1. COINCIDAN PASSWORD
            if (password2 !== password) {
                setValidarPassword(true)
                setTextoValidarPassword('Las contraseñas no coinciden')
                setValidarPassword2(true)
                setLoading(false)

                //  PASSWORD FORMATO CORRECTO
            } else if (!correcto) {
                setLoading(false)
                setValidarPassword(true)
                setValidarPassword('La contraseña no cumple lo solicitado: 8 caracteres, 1 letra mayúscula, 1 letra minúscula y 1 número')
            } else {



                await axios.post(`${process.env.REACT_APP_API_INNOVACONNECT}/sign-up/verify-code`, {
                    email: email,
                    token: tokenVerifyCode,
                    code: codigoVerificacion
                })
                    .then(async (data) => {
                        let respuestaApi = data.data
                        // console.log('verify code', respuestaApi)

                        if (respuestaApi === 'OK') {
                            await axios.post(`${process.env.REACT_APP_API_INNOVACONNECT}/sign-up/create-user`, {
                                email: email,
                                name: nombre,
                                last: apellido,
                                pwd: password,
                                code: parseInt(codigoVerificacion)
                            }).then(async (data) => {
                                let respuestaApi = data.data;
                                // console.log('create user',respuestaApi)
                                // console.log(respuestaApi)
                                if (respuestaApi === 'OK') {
                                    let login = await submitLogin(email, password)
                                    // console.log('inicie sesion' + login)

                                    handleNext()
                                    // setLoading(false)
                                    setLoading(false)
                                } else {
                                    setAlert(true)
                                    setTextoAlert(data.data.mensaje)
                                    setLoading(false)

                                }
                            })
                                .catch(() => {
                                    setAlert(true)
                                    setTextoAlert('ERROR, reintente')
                                    setLoading(false)
                                })

                        } else {
                            setTextoValidarCodigoVerificacion(data.data.mensaje)
                            setValidarCodigoVerificacion(true)
                            setLoading(false)
                        }

                    })
                    .catch((error) => {
                        // console.log("entre catch send code")
                        setLoading(false)
                        Swal.fire({
                            icon: 'error',
                            text: 'Ha ocurrido un error intente nuevamente',
                        })
                        console.log('[ERROR]: verify-code', error)
                        return;
                    })
            }

        }
    }

    const submitLogin = async (email, password) => {
        let navBar2 = false
        let sesionIniciada = false
        let navBar = props.navBar2 ? props.navBar2 : navBar2;
        await axios.post(`${process.env.REACT_APP_API_INNOVACONNECT}/landing/verifyUser`, {
            email: email,
            password: password
        })
            .then(async (data) => {
                // console.log(data.data)
                let res = data.data.error;
                if (res === 0) {
                    // LOGIN Qaconnect/loginUE SEVUELVE TOKEN
                    await axios.post(`${process.env.REACT_APP_API_INNOVACONNECT}/landing/loginToken`, {
                        password: password,
                        email: email.toLowerCase()
                    })
                        .then(async (data) => {
                            let res = data.data.error
                            // console.log("inicie sesion ok")
                            console.log(data.data)
                            let token = data.data.token
                            let token2 = data.data.token_2
                            if (res === 0 || res === '0') {
                                localStorage.setItem('token_2', token)
                                localStorage.setItem('token_3', token2)
                                localStorage.setItem('email', email)
                                handleNext()
                                dispatch(fetchEstadoTrue());
                            }
                            await axios.post(`${process.env.REACT_APP_API_STREAMING}/datosuser`, {
                                email: localStorage.getItem('email')
                            },{headers:{authorization: token2}}).then((response) => {
                                let informacion = response.data


                                const noValido = / /
                                if (noValido.test(informacion.name)) {
                                    let nombre = informacion.name.split(" ",)
                                    let nombrep = nombre[0].charAt(0).toUpperCase();
                                    let apellido = nombre[1].charAt(0).toUpperCase();
                                    let n = nombre[0].charAt(0).toUpperCase() + nombre[0].slice(1);
                                    let a = nombre[1].charAt(0).toUpperCase() + nombre[1].slice(1);
                                    informacion.nombreAbreviado = `${nombrep}${apellido}`
                                    informacion.nombre = `${n}`
                                    informacion.apellido = `${a}`
                                } else {
                                    let nombre = informacion.name
                                    let n = nombre[0].charAt(0).toUpperCase() + nombre[0].slice(1);
                                    informacion.nombreAbreviado = n
                                    informacion.nombre = nombre
                                    informacion.apellido = ''
                                }

                                dispatch(fetchInfoUser(informacion))
                                if (navBar) {
                                    props.infoUsuario()
                                    sesionIniciada = true
                                }
                            })
                            .catch((error) => {
                                console.log('ERROR :', error.response.data.error)
                            })

                        }).catch((error) => console.log(error))
                }
                return sesionIniciada

            })
            .catch((error) => {
                setLoading(false)
                Swal.fire({
                    icon: 'error',
                    // title: 'Oops...',
                    text: 'Ha ocurrido un error intenta nuevamente',
                })
                console.log('[ERROR]:', error)
            })

    }
    return (
        <div>
        {!irLogin1 ?  <div>
        {/*<div style={{textAlign: 'end'}}>*/}
        {/*    <IconButton aria-label="fingerprint" color="secondary" onClick={() => props.cerrarModal()}>*/}
        {/*        <CloseIcon style={{color: 'grey',}} fontSize={"medium"}/>*/}
        {/*    </IconButton>*/}
        {/*</div>*/}
        <div style={{textAlign: 'center', marginTop: '-13px',
            whiteSpace: 'pre-line'}}>
            <h3 className="theme-color"
                style={{fontFamily: 'Lato'}}>{mostrarPasoaPaso ? 'REGISTRAR CUENTA' : 'CREA UNA CUENTA FREE TRIAL POR \n 30 DÍAS'}</h3>
        </div>

        <div style={{width: '100%', height: '30%', textAlign: 'center'}}>
            <img src={logoInnova} width={170} height={115} alt="logo innova"/>
        </div>
        <br/>
        {mostrarPasoaPaso ? <Box sx={{width: '100%'}}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}
                          sx={{
                              '& .MuiStepLabel-root .Mui-completed': {
                                  color: '#f6601d', // circle color (COMPLETED)
                              },
                              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                  {
                                      color: 'common.white', // Just text label (COMPLETED)
                                  },
                              '& .MuiStepLabel-root .Mui-active': {
                                  color: '#f6601d', // circle color (ACTIVE)
                              },
                              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                  {
                                      color: 'common.white', // Just text label (ACTIVE)
                                  },
                              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                  fill: 'common.white', // circle's number (ACTIVE)
                              }
                          }}
                    >
                        <StepButton color="inherit">
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </Box> : ' '}


        <div style={{marginTop: '15px'}}>
            <Form>
                {activeStep === 0 ? <Row>
                    <div style={{marginBottom: '15px'}}>
                        <p style={{lineHeight: '18px', fontSize: '17px', fontWeight: 600}}>
                            Explora soluciones, visualiza scoutings activos, comparte tus soluciones, conecta con el ecosistema
                            de innovación de Latam, lanza tus propios llamados y desafíos.
                        </p>
                        <p style={{lineHeight: '18px', fontSize: '17px', fontWeight: 600}}>
                            Después de los 30 días podrás acceder a la plataforma, previa suscripción, en sus dos modalidades de pago: mensual o anual.                            </p>

                        <div style={{lineHeight: '18px'}}> <span style={{color: 'grey'}}>
                                 (*) Si eres una startup de base tecnológica, sube tu solución para
                                obtener acceso gratuito de forma permanente (previa validación) </span></div>

                    </div>
                </Row> : activeStep === 1 ?
                    <Row>
                        <Col md="12">
                            <div className="contenedorInput">
                                <TextField
                                    label=" Nombre"
                                    id="nombre"
                                    onChange={(e) => handleInputs(e, 'nombre')}
                                    value={nombre}
                                    size="small"
                                    fullWidth
                                    helperText={textoValidarNombre}
                                    error={validarNombre}
                                    variant="standard"/>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="contenedorInput">
                                <TextField
                                    label=" Apellido"
                                    id="apellido"
                                    value={apellido}
                                    onChange={(e) => handleInputs(e, 'apellido')}
                                    size="small"
                                    fullWidth
                                    error={validarApellido}
                                    helperText={textoValidarApellido}
                                    variant="standard"/>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="contenedorInput">
                                <TextField
                                    label=" Email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => handleInputs(e, 'email')}
                                    size="small"
                                    helperText={textoValidarEmail}
                                    fullWidth
                                    error={validarEmail}
                                    variant="standard"/>
                            </div>
                        </Col>
                    </Row> :
                    activeStep === 2 ?
                        <Row>
                            <Col md="12">
                                <div className="contenedorInput">
                                    <TextField
                                        label="Código verificación"
                                        id="codigoVerificacion"
                                        onPaste={(e) => e.preventDefault()}
                                        value={codigoVerificacion}
                                        onChange={(e) => handleInputs(e, 'codigoVerificacion')}
                                        size="small"
                                        fullWidth
                                        helperText={textoValidarCodigoVerificacion}
                                        error={validarCodigoVerificacion}
                                        variant="standard"/>
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="contenedorInput" style={{
                                    display: 'flex'
                                }}>
                                    <TextField
                                        type={eye}
                                        value={password}
                                        onPaste={(e) => e.preventDefault()}
                                        label="Contraseña"
                                        helperText={textoValidarPassword}
                                        error={validarPassword}
                                        fullWidth
                                        onChange={(e) => handleInputs(e, 'password')}
                                        variant="standard"
                                        size="small"/>
                                    <div style={{marginTop: '7px'}}>
                                        {eye === 'password' ?
                                            <IconButton aria-label="delete" onClick={() => handdlePassword()}>
                                                <Visibility/>
                                            </IconButton> :
                                            <IconButton aria-label="VisibilityOffIcon"
                                                        onClick={handdlePassword}>
                                                <VisibilityOffIcon/>
                                            </IconButton>}
                                    </div>
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="contenedorInput" style={{
                                    display: 'flex'
                                }}>
                                    <TextField
                                        type={eye2}
                                        label="Confirmar contraseña"
                                        fullWidth
                                        onPaste={(e) => e.preventDefault()}
                                        value={password2}
                                        error={validarPassword2}
                                        helperText={textoValidarPassword2}
                                        onChange={(e) => handleInputs(e, 'password2')}
                                        variant="standard"
                                        size="small"/>
                                    <div style={{marginTop: '7px'}}>
                                        {eye2 === 'password' ?
                                            <IconButton aria-label="delete" onClick={() => handdlePassword2()}>
                                                <Visibility/>
                                            </IconButton> :
                                            <IconButton aria-label="VisibilityOffIcon"
                                                        onClick={() => handdlePassword2()}>
                                                <VisibilityOffIcon/>
                                            </IconButton>}

                                    </div>
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="contenedorInput" style={{
                                    display: 'flex'
                                }}>
                                    <div style={{color: 'black', marginLeft: '-9px'}}>
                                        <Checkbox
                                            checked={check}
                                            onChange={(e) => handleInputs(e, "check")}
                                            inputProps={{'aria-label': 'controlled'}}
                                            onError={true}
                                            sx={{
                                                color: '#fc7c45',
                                                '&.Mui-checked': {
                                                    color: '#fd601c',
                                                },
                                            }}
                                        />
                                        <span style={{fontSize: '15px', fontWeight: '400'}}>Estoy de acuerdo con las
                                                <a style={{color: '#fd601c', marginLeft: '4px'}} target="_blank" rel="noreferrer"
                                                   href={'https://firebasestorage.googleapis.com/v0/b/landing-innova-connect-react.appspot.com/o/pol%C3%ADticas%20innovaconnect%2FPoliticas%20de%20Uso%20Innovaconnect%2015032023.docx.pdf?alt=media&token=484c1658-763b-42d4-8c48-3c7e49e6136f'}><u><b>políticas de uso</b></u></a></span>
                                        <FormHelperText style={{color:'#d32f2f', marginLeft:'9px'}}>{textoValidarPoliticas}</FormHelperText>
                                    </div>
                                </div>
                            </Col>
                        </Row> :
                        activeStep === 3 ?
                            <Row>
                                <Col md="12">
                                    <div className="contenedorInput">
                                        <div style={{textAlign: 'center', marginTop: '20px'}}>
                                            <h3>¡Has sido registrado exitosamente!</h3>
                                            <h4>
                                                    <span>Bienvenido a <b
                                                        className="theme-color">InnovaConnect</b></span>
                                            </h4>
                                            <p style={{fontSize: '17px', fontWeight: '600'}}>
                                                Ya puedes comenzar a disfrutar de los beneficios de
                                                nuestra plataforma.</p>
                                        </div>
                                    </div>
                                </Col>

                            </Row> : ''}

                {alert === true ? <div style={{marginTop: '5%', marginLeft: '20px', marginRight: '20px'}}>
                    <Alert severity="error">
                        {textoAlert}
                    </Alert>

                </div> : ''}


                {loading === false ? <div className="contenedorBoton ">
                        {
                            activeStep === 0 ?
                                <div>
                                    {loading === true ? <div className="contenedorBoton "><LoadingButton
                                            loading
                                            loadingPosition="start"
                                            size="small"
                                            startIcon={<SaveIcon/>}
                                            variant="outlined"
                                        >
                                            Cargando
                                        </LoadingButton>
                                        </div> :
                                        <Button id="styleButton" size="sm"
                                                onClick={() => handlePaso0()}>ACEPTAR</Button>
                                    }
                                </div> :
                                activeStep === 1 ?
                                    <div>
                                        {!irLogin ? <Button id="styleButton" size="sm"
                                                           onClick={() => handdleBack()}>ATRAS</Button> :
                                            <Button id="styleButton" size="sm"
                                                    onClick={() => handleIrLogin()}>LOGIN</Button>}
                                        {loading === true ? <div className="contenedorBoton "><LoadingButton
                                                loading
                                                loadingPosition="start"
                                                size="small"
                                                startIcon={<SaveIcon/>}
                                                variant="outlined"
                                            >
                                                Cargando
                                            </LoadingButton>
                                            </div> :
                                            <Button id="styleButton" size="sm"
                                                    disabled={irLogin}
                                                    onClick={() => handlePaso1()}>SIGUIENTE</Button>
                                        }
                                    </div> :
                                    activeStep === 2 ?
                                        <div>
                                            <Button id="styleButton" size="sm"
                                                    onClick={() => handdleBack()}>ATRAS</Button>
                                            {loading === true ? <div className="contenedorBoton "><LoadingButton
                                                loading
                                                loadingPosition="start"
                                                size="small"
                                                startIcon={<SaveIcon/>}
                                                variant="outlined"
                                            >
                                                Cargando
                                            </LoadingButton>
                                            </div> : <Button id="styleButton" size="sm"
                                                             onClick={() => handlePaso2()}>SIGUIENTE</Button>
                                            }

                                        </div> :
                                        activeStep === 3 ? <Button id="styleButton" size="sm"
                                                                   onClick={() => handlePaso3()}>ACEPTAR</Button> :
                                            ''}


                    </div>
                    : <div className="contenedorBoton "><LoadingButton
                        loading
                        loadingPosition="start"
                        size="small"
                        startIcon={<SaveIcon/>}
                        variant="outlined"
                    >
                        Cargando
                    </LoadingButton>
                    </div>
                }
            </Form>
        </div>
    </div> : <Login />}
        </div>

    )
};


export default RegistrarCuenta;