import React from 'react';
import HomeOlive from './pages/HomeOlive';
import BlogDetails from './pages/BlogDetails';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import MiCuenta from "./pantallas/Mi_cuenta/micuenta";
import {Provider} from "react-redux";
import store from "./contexts/Redux/index";
import Soporte from "./pantallas/Soporte/soporte.js"

const App = () => {
    return (
        <div className="App">
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route exact path={'/'} component={HomeOlive}/>
                        <Route  path={`/micuenta`} component={MiCuenta}/>
                        {/*<Route exact path={`${process.env.PUBLIC_URL}/home-two`} component={HomeHorizontal}/>*/}
                        <Route path={`/casosexito/:id`} component={BlogDetails}/>
                        {/*<Route exact path={`${process.env.PUBLIC_URL}/BlogDetails`} render={(props) => <BlogDetails {...props}   />} />*/}
                    </Switch>
                </Router>
            </Provider>
            <Soporte />
        </div>

    )
        ;
}


export default App;
