import {
  empresa_1,
  empresa_2,
  empresa_3,
  empresa_4,
  empresa_5,
  empresa_6,
  empresa_7,
    empresa_8
} from "./picturesExport";

const sportCarsArray = [
  {
    id: 3,
    logo: empresa_1,
    text: `bci`,
    width: "150px",
  },
  {
    id: 0,
    logo: empresa_4,
    text: "cmp",
    width: "150px",
  },
  {
    id: 7,
    logo: empresa_8,
    text: "cocacola",
    width: "200px",
  },
  {
    id: 1,
    logo: empresa_2,
    text: `sura`,
    width: "150px",
  },
  {
    id: 2,
    logo: empresa_3,
    text: `agrosuper`,
    width: "150px",
  },
  {
    id: 5,
    logo: empresa_6,
    text: "walmart",
    width: "150px",
  },
  {
    id: 6,
    logo: empresa_7,
    text: "sonda",
    width: "150px",
  },
  {
    id: 4,
    logo: empresa_5,
    text: "comfenalco",
    width: "150px",
  },

];


export { sportCarsArray };
